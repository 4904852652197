@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css');

*{
  cursor: default;
}

body {
  padding: 0;
  margin: 0;
  color: #3e4957;
  background-color: #f5f5f5 !important;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
}

ul,
li {
  list-style: none;
  padding: 0 !important;
  margin: 0;
}

.btn-outline-lightGray {
  --bs-btn-color: #3e4957;
  --bs-btn-border-color: #dde0e4;
  --bs-btn-hover-color: #3e4957;
  --bs-btn-hover-bg: #f5f5f5;
  --bs-btn-hover-border-color: #dde0e4;
  --bs-btn-focus-shadow-rgb: 245, 245, 245;
  --bs-btn-active-color: #3e4957;
  --bs-btn-active-bg: #f5f5f5;
  --bs-btn-active-border-color: #dde0e4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f5f5f5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dde0e4;
  --bs-gradient: none;
}

.nav {
  --bs-nav-link-color: #3e4957;
}

// bootstrap 4 sort
th .order-4:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

th .order-4:after {
  content: '\2193';
  opacity: 0.4;
}

th .caret-4-asc:before {
  margin-left: 3.5px;
  content: '\2191';
}

th .caret-4-asc:after {
  content: '\2193';
  opacity: 0.4;
}

th .caret-4-desc:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

th .caret-4-desc:after {
  content: '\2193';
}

thead {
  border-bottom: 2px solid black;
}

.page-link {
  border: none;
  color: #3e4957;
  border-radius: 8px;
  font-size: 20px;
  padding: 6px 16px!important;
  cursor: pointer!important;

}
.page-link:hover{
  background-color: #F5E7F8!important;
  color:#9B27AF!important;
  .custom-pagination-arrow{
    fill:#9B27AF;
  }
}

.active > .page-link {
  background-color: #9B27AF;
  color:#FFFFFF;
  font-weight: bold;
}
.active > .page-link:hover{
  background-color: #9B27AF!important;
  color:#FFFFFF!important;
  }

.pagination{
  --bs-pagination-focus-box-shadow:null!important;
}
.page-item:not(:first-child) .page-link{
  margin-left: 0;
}
.page-item:first-child .page-link:focus, .page-item:last-child .page-link:focus {
  background-color: white;
}
.disabled > .page-link  .custom-pagination-arrow {
  fill:#DDE0E4;
}
 .custom-pagination-arrow {
   fill:#3e4957;
   cursor: pointer!important;
   margin-bottom: 2px;
 }

.custom-pagination-arrow > *{
  cursor: pointer!important;
}

.react-bootstrap-table-pagination-list{
  width: 100%!important;
  display: flex;
  justify-content: center;
}
.cursor-pointer {
  cursor: pointer;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  display: none;
}

.apply-btn {
  --bs-btn-color: #5842be;
  --bs-btn-border-color: #5842be;
  --bs-btn-hover-color: #dde0e4;
  --bs-btn-hover-bg: #5842be;
  --bs-btn-hover-border-color: #dde0e4;
  padding: 14px 4px 14px 4px !important;
}

.clear-btn {
  --bs-btn-color: #1f3354;
  --bs-btn-border-color: #1f3354;
  --bs-btn-hover-color: #1f3354;
  --bs-btn-hover-bg: #DEDEDE;
  --bs-btn-hover-border-color: #1f3354;
  padding: 14px 4px 14px 4px !important;
}

.pos-primary-btn {
  --bs-btn-active-color: #dde0e4;
  --bs-btn-active-bg: #5842be;
  --bs-btn-color: #000000;
  --bs-btn-active-border-color: #dde0e4;
}

.custom-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 6px;
  width: 100%;
}
.custom-dropdown > .dropdown-menu {
  overflow: auto !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 12px;

}
.custom-dropdown-menu .dropdown-item{
  overflow: auto !important;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 16px!important;
  color: #1F3354;
  &.active{
    color: #ffffff;
    background-color: #5842BE;
  }
  &.active:hover{
    color: #ffffff;
    background-color: #5842BE;
  }
  &:not(:last-child){
  margin-bottom: 8px;
}
}

.custom-dropdown-menu .dropdown-item:hover{
  background-color: #EEECF9;
}

.me-pos {
  background-color: white !important;
  font-family: Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  height: 90%;
  overflow-x: hidden;
}

.me-pos.btn,
.me-pos button {
  font-size: 14px !important;
}

.rounder-4px {
  border-radius: 4px !important;
}

.me-pos .btn-primary {
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-bg: white !important;
  --bs-btn-color: #1f3354 !important;
  --bs-btn-border-color: #dedede !important;
  --bs-btn-hover-color: #1f3354 !important;
  --bs-btn-hover-bg: white !important;
  --bs-btn-focus-shadow-rgb: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-border-color: #dedede !important;
}
.me-pos .btn-primary .btn:disabled, .btn.disabled, fieldset:disabled .btn{
  background-color: white;
  color: #6c757d;
}

.me-pos .form-select:focus {
  border-color: #3e4957;
  box-shadow: 0 0 0 0;
  background-color: white;
  color: #1f3354;
}

.bold-fonts {
  font-size: 16px;
  font-weight: 700;
}
.font-16 {
  font-size: 16px;
}

.h4-heads {
  font-weight: 400;
  font-size: 28px;
}

.primary-color {
  color: #1f3354;
}

//.me-pos .dropdown-item {
//  --bs-dropdown-link-active-bg: #3e4957;
//  font-size: 14px;
//}

.color-me-pos {
  color: #5842be;
}

.scroll-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  bottom:50px;
  right: 50px;
  position: fixed;
  color: #5842BE;
  background-color: #EEECF9;
}

.scroll-button i {
  cursor: pointer;
  font-size: 40px !important
}


.pagination-link {
  font-size: 0.875rem;
  padding: 3px 9px !important;
}

.loader {
  width: 100%;
  height: 100%;
  width: 44px;
  height: 44px;
  position: relative;
  display: inline-block;
  margin: 0 16px;
  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    display: block;
    background: #5628ee;
    top: 37px;
    left: 19px;
    transform: translate(-18px, -18px);
    animation: dotRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    rect,
    polygon,
    circle {
      fill: none;
      stroke: #2f3545;
      stroke-width: 10px;
      stroke-linejoin: round;
      stroke-linecap: round;
    }
    polygon {
      stroke-dasharray: 145 (221 - 145) 145 (221 - 145);
      stroke-dashoffset: 0;
      animation: pathTriangle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
    rect {
      stroke-dasharray: (256 / 4 * 3) (256 / 4) (256 / 4 * 3) (256 / 4);
      stroke-dashoffset: 0;
      animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
    circle {
      stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
      stroke-dashoffset: 75;
      animation: pathCircle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
  }
  &.triangle {
    width: 48px;
    &:before {
      left: 21px;
      transform: translate(-10px, -18px);
      animation: dotTriangle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
  }
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }
  66% {
    stroke-dashoffset: 147;
  }
  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }
  66% {
    transform: translate(10px, -18px);
  }
  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }
  50% {
    stroke-dashoffset: 128;
  }
  75% {
    stroke-dashoffset: 192;
  }
  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(18px, -18px);
  }
  75% {
    transform: translate(0, -36px);
  }
  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }
  50% {
    stroke-dashoffset: 175;
  }
  75% {
    stroke-dashoffset: 225;
  }
  100% {
    stroke-dashoffset: 275;
  }
}
.main-view{
  margin: auto 9.2rem;
  @media screen and (max-width: 744px){
    margin: 0;
  }
}

.scrollbar::-webkit-scrollbar,
body::-webkit-scrollbar{
  width: 10px;
  height: 10px;
}

.scrollbar::-webkit-scrollbar-track,
body::-webkit-scrollbar-track{
  background: #F1F1F1;
}
.scrollbar::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb{
  background-color: #C4C4C4;
  border-radius: 20px;
}

.me-ticket {
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.me-ticket .bg-secondary {
  background-color: rgba(#E5FAED, var(--bs-bg-opacity)) !important;
}

.me-ticket .text-primary {
  color: rgba(#16B054, var(--bs-text-opacity)) !important;
}

.me-ticket .bg-primary {
  background-color: rgba(#16B054, var(--bs-bg-opacity)) !important;
}

.me-ticket .btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #16B054;
  --bs-btn-border-color: #16B054;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #11803f;
  --bs-btn-hover-border-color: #11803f;
  --bs-btn-focus-shadow-rgb: 170, 71, 187;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #11803f;
  --bs-btn-active-border-color: #11803f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #16B054;
  --bs-btn-disabled-border-color: #16B054;
}

.me-ticket .btn-outline-lightGray {
  --bs-btn-color: #3e4957;
  --bs-btn-border-color: #dde0e4;
  --bs-btn-hover-color: #3e4957;
  --bs-btn-hover-bg: #f5f5f5;
  --bs-btn-hover-border-color: #dde0e4;
  --bs-btn-focus-shadow-rgb: 245, 245, 245;
  --bs-btn-active-color: #3e4957;
  --bs-btn-active-bg: #ececec;
  --bs-btn-active-border-color: #dde0e4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f5f5f5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dde0e4;
  --bs-gradient: none;
}

.me-ticket .rdrInRange {
  background-color: #E5FAED !important;
}

.me-ticket .dropdown-item.active, .dropdown-item:active {
  color: #16B054;
  text-decoration: none;
  background-color: rgba(#E5FAED, var(--bs-bg-opacity)) !important;
}

.me-ticket .dropdown-toggle::after {
  content: none;
}

//.react-bootstrap-table-page-btns-ul .page-item.active{
//  background-color: #9B27AF!important;
//}

.filters-button {
  --bs-btn-color: #5842BE !important;
  --bs-btn-border-color: #5842BE !important;
  --bs-btn-bg: #f3f3f3 !important;
  --bs-btn-hover-color: #5842BE !important;
  --bs-btn-hover-bg: #F9F8FD !important;
  --bs-btn-hover-border-color: #5842BE !important;
  --bs-btn-focus-shadow-rgb: #5842BE !important;
  --bs-btn-active-color: #5842BE !important;
  --bs-btn-active-bg: #EEECF9 !important;
  --bs-btn-active-border-color: #5842BE !important;
}

.ticket-border-gray {
  border-color: #3E4957 !important;
}

.pagination {
  --bs-pagination-bg: transparent !important;
  --bs-pagination-disabled-bg: transparent !important;
  --bs-pagination-active-color: transparent !important;
  --bs-pagination-border-color: transparent !important;
  .page-link {
    font-size: 14px;
    padding: 4px 8px !important;
  }
}

.me-qr .btn-primary {
  --bs-btn-disabled-bg: #DDE0E4;
  --bs-btn-disabled-border-color: #DDE0E4;
}

.checkbox-me-page {
  .form-check-input:checked {
    background-color: #3A83F6 !important;
    border-color: #3A83F6 !important;
  }
}

.pagination-me-page {
  .active > .page-link {
      background-color: #3A83F6;
      &:hover{
        color: #fff!important;
      }
  }
  .page-link:hover{
    background-color: #E0EDFE!important;
    color:#3A83F6!important;
    .custom-pagination-arrow{
      fill:#3A83F6;
    }
  }
  .active > .page-link:hover{
    background-color: #3A83F6!important;
  }
}