$primary: #9b27af;
$secondary: #f5e7f8;
$font-family-sans-serif: 'Arial';
$headings-font-weight: 700;
$gray: #8b929a;
$lightGray: #f5f5f5;
$success: #4caf50;
$danger: #f44336;
$darkgray: #dde0e4;
$dark: #1f3354;
$info: #9d9d9d;
$violet-hover: #382788;
$violet-light: #eeecf8;
$pos-main: #5842be;
$primary2: #3a83f6;
$secondary2: #e1edff;
$orange: #F8A40F;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'gray': $gray,
  'lightGray': $lightGray,
  'success': $success,
  'danger': $danger,
  'darkgray': $darkgray,
  'dark': $dark,
  'info': $info,
  'violet-hover': $violet-hover,
  'violet-light': $violet-light,
  'pos-main': $pos-main,
  'primary2': $primary2,
  'secondary2': $secondary2,
  'orange': $orange
);

//fonts
$h1-font-size: 3rem; //(48px)
$h2-font-size: 2.625rem; //(42px)
$h3-font-size: 2rem; //(32px)
$h4-font-size: 1.5rem; //(24px)
$h5-font-size: 1.25rem; //(20px)
$h6-font-size: 1.125rem; //(18px)
$h7-font-size: 0.875rem; //(14px)

$font-sizes: (
  1: 2.5rem,
  2: 2rem,
  3: 1.75rem,
  4: 1.5rem,
  5: 1.25rem,
  6: 1rem,
  7: 0.875rem,
  8: 1.125rem
);
$enable-rfs: false;

@import '~bootstrap/scss/bootstrap';
@import './app.scss';
@import '@me-pos/category-picker';
