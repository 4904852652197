.loaderContainer {
    display: inline-block;
    animation: rotate 1s infinite linear; /* Define animation */
  }
  
  .loaderPath {
    transform-origin: center;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg); /* Rotate element 360 degrees */
    }
  }